@import url(https://fonts.googleapis.com/css2?family=Oswald&display=swap);
html {
	scroll-behavior: smooth;
}

html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	overflow-x: hidden;
}

body {
	margin: 0;
	overflow-x: hidden;
	font-family: 'Oswald', sans-serif;
	font-size: 13px;
	color: #0a0a0a;
	background: #fff;
}

footer {
	background-color: #000;
	color: #fff;
}

.description {
	top: 0;
	bottom: 0;
	height: 100%;
	margin: 25px 0;
}

.bg-dark {
	background-color: #000 !important;
}

.navbar-dark .navbar-text {
	color: rgba(255, 255, 255, 0.75);
}

@media (min-width: 768px) {
	.description {
		position: absolute;
		top: 30%;
		font-size: 1.15rem;
	}
}

@media (max-width: 768px) {
	.container-adaptive {
		padding: 0;
	}
}

