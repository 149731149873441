@import url('https://fonts.googleapis.com/css2?family=Oswald&display=swap');

html {
	scroll-behavior: smooth;
}

html,
body {
	margin: 0;
	padding: 0;
	width: 100%;
	overflow-x: hidden;
}

body {
	margin: 0;
	overflow-x: hidden;
	font-family: 'Oswald', sans-serif;
	font-size: 13px;
	color: #0a0a0a;
	background: #fff;
}
